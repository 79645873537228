import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import bootsratp from 'bootstrap/dist/js/bootstrap.bundle.js'
import "@/assets/scss/style.scss"
import router from './router'
import VueScrollTo from 'vue-scrollto';
import "@/assets/css/swiper.min.css"
import "@/assets/css/materialdesignicons.min.css"
import { VueTypedJs } from 'vue-typed-js'
import VueEasyLightbox from 'vue-easy-lightbox'
import money from 'v-money';
import { createPinia } from 'pinia';
import axios from 'axios';
import './interceptor/interceptor';


//axios for external api calls
export const externalInstance = axios.create();

const app = createApp(App);
const pinia = createPinia();

app.use(VueEasyLightbox)
app.use(VueTypedJs)
app.use(money)
app.use(router)
app.use(VueScrollTo)
app.use(bootsratp)
app.use(pinia)
app.mount('#app')
