<template>
    <div>
        <!-- START NAVBAR -->
        <nav id="navbar" class="navbar navbar-expand-lg fixed-top sticky nav-sticky">
            <div class="container">
                <div style="margin-top: 10px;caret-color: transparent;">
                <a class="navbar-brand" href="">
                            <router-link to="/"><h4 class="display-7 l-dark" style="font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">
                            <img style="width:50px;height: 50px;margin-bottom: 10px;" :src="require('@/assets/images/brands/texsmart_logo.png')" alt="">
                            Texsmart Realty Inc</h4></router-link>  
                </a>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation" @click="handler">
                    <i data-feather="menu"></i>
                </button>
                <div class="collapse  navbar-collapse" :class="{ hidden: !toggle }" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0" id="navbar-navlist">
                        <li v-if="!isSignedIn" class="nav-item dropdown">
                            <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                                Listings
                            </button>
                            <ul id="services-box" class="dropdown-menu dropdown-menu-light">
                            <li><a class="nav-link dropdown-item" href="#">Coming Soon</a></li>
                            </ul>
                        </li>
                        <li class="nav-item ms-0 contact">
                            <router-link class="nav-link" :to="{ name: 'ContactPage' }">Contact Us</router-link>
                        </li>
                        <li class="nav-item ms-0 team">
                            <router-link class="nav-link" :to="{ name: 'OurAgents' }">Our Agents</router-link>
                        </li>
                        <li v-if="isSignedIn" class="nav-item ms-0 team">
                            <router-link class="nav-link" :to="{ name: 'CdaForm' }">Request a CDA</router-link>
                        </li>
                    </ul>


                    <ul v-if="!isSignedIn"
                     class="list-inline menu-social mb-0 ps-lg-4 ms-2">
                        <li class="list-inline-item">
                            <router-link :to="{ name: 'AuthLogin' }"
                                class="btn btn-primary">Agent Login</router-link>
                        </li>
                    </ul>

                    <ul v-else class="list-inline menu-social mb-0 ps-lg-4 ms-2">
                        <li class="btn btn-primary" @click="signOut()">
                            Sign Out
                        </li>
                    </ul>

                    <ul v-if="isSignedIn"
                    class="list-inline menu-social mb-0 ps-lg-4 ms-2" >
                        <li class="nav-item ms-0 team" style="font-family:Arial, Helvetica, sans-serif;font-weight: 600;color: #333333;">
                            Hello&nbsp; 
                            <span style="font-size: 20px;font-weight: 700;">
                                {{ authStore.getUserInfo != null ? authStore.getUserInfo.firstName : null }}
                            </span>
                        </li>
                    </ul>
                </div>
                <!--end collapse-->
            </div>
            <!--end container-->
        </nav>
        <!-- END NAVBAR -->
    </div>
</template>

<script>
import feather from 'feather-icons';
import { useAuthStore } from '@/stores/store';

export default {
    name: "navbarVue",
    setup() {
        const authStore = useAuthStore();

        return {
            authStore
        }
    },
    data() {
        return {
            toggle: false,
            scroll: true,
            sections: document.querySelectorAll("section"),
            navLi: document.querySelectorAll("nav .container .collapse ul li"),
            current: "",
            sectionTop: null,
        }
    },
     mounted() {
        feather.replace();
    },
    computed: {
        isSignedIn() {
            return this.authStore.getUserInfo != null;
        }
    },
    methods: { 
        async signOut() {
            await this.authStore.signOut();
            this.$router.push({path: "/"});
        },
        handler: function () {
            this.toggle = !this.toggle;
        },

        handleScroll(event) {
            const navbar = document.getElementById("navbar");
            console.log(event);
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        },

        onscroll() {
            document.querySelectorAll("section").forEach((section) => {
                this.sectionTop = section.offsetTop;
                if (pageYOffset >= this.sectionTop - 60) {
                    this.current = section.getAttribute("id");
                }
            });
            document.querySelectorAll("nav .container .collapse ul li").forEach((li) => {
                if (li.classList.contains(this.current)) {
                    li.classList.add("active");
                } else {
                    li.classList.remove("active");

                }
            });
        }
    },


}
</script>

<style lang="scss" scoped>
.l-dark {
    background: -webkit-linear-gradient(#20A4F3,#addaf4);
    -webkit-background-clip: text;
    letter-spacing: 1px;
    -webkit-text-fill-color: transparent;
}
.l-light {
    background: -webkit-linear-gradient(#20A4F3,#addaf4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#services-box {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px, rgba(0, 0, 0, 0.22) 0px 2px 2px !important;
    border-radius: 0% !important;
}
#services-box .nav-link {
    padding: 5px 5px !important;
}
</style>