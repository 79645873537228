<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/store';

export default {
  name: 'App',
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  mounted() {
    this.authStore.refreshUserData();
  },
}
</script>

<style></style>