import { defineStore } from 'pinia';
import authenticationMixin from '@/mixins/authenticationMixin';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    //userInfo: null,
    status: null,
    jwtToken: null,
    lastRoute: null,
    
    // User and Authentication Data
    authDataInStore: null,
  }),
  actions: {
    async refreshUserData() {
      //check if the browser has user data
      let authDataInBrowser = JSON.parse(window.localStorage.getItem('TEXSMART_REALTY_USERDATA'));
      if (authDataInBrowser == null || authDataInBrowser == undefined) {
        this.authDataInStore = null;
      } else {
        this.authDataInStore = authDataInBrowser;
      }
    },

    async updateUserDetails(userInfo) {
      const toTitleCase = function (text) {
        return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
      };
      //update user details in browser
      let authDataInBrowser = JSON.parse(window.localStorage.getItem('TEXSMART_REALTY_USERDATA'));
      authDataInBrowser.firstName = toTitleCase(userInfo.firstName);
      authDataInBrowser.lastName = toTitleCase(userInfo.lastName);
      authDataInBrowser.licenseNumber = userInfo.realtorLicenseNumber;
      window.localStorage.setItem('TEXSMART_REALTY_USERDATA', JSON.stringify(authDataInBrowser));
      //update user details in store
      this.authDataInStore = JSON.parse(JSON.stringify(authDataInBrowser));
    },
    
    async saveAuthData(authData) {
        //save authentication data in application storage
        this.authDataInStore = JSON.parse(JSON.stringify(authData));

        //save authentication data in browser local storage
        window.localStorage.setItem('TEXSMART_REALTY_USERDATA', JSON.stringify(this.authDataInStore));
    },

    async handleTokenValidation(renewToken=false) {
      return await authenticationMixin.methods.handleTokenValidationAndRenewal(renewToken);
    },

    async renewToken(refreshToken) {
        let response = await authenticationMixin.methods.getNewAuthToken(refreshToken);
        var authData = await this.getUserInfo;
        if(response.data != undefined && response.status == 200) {
          authData.access_token = response.data.access_token;
          authData.refresh_token = response.data.refresh_token;
          authData.token_type = response.data.token_type;
          authData.expires_in = response.data.expires_in;
          authData.refresh_token_expires_in = response.data.refresh_token_expires_in;
          // Calculate new expiration time of access token
          let updatedAuthData = authenticationMixin.methods.calculateAccessTokenExpirationTime(authData);
          // Calculate new expiration time of refresh token
          updatedAuthData = authenticationMixin.methods.calculateRefreshTokenExpirationTime(updatedAuthData);
          //save the updated data in both store and browser
          await this.saveAuthData(updatedAuthData);
          return true;
        } else {
          await this.signOut();
          return false;
        }
    },

    async saveToRouteAfterAuthentication(routeName) {
      this.lastRoute = routeName;
    },

    async signOut() {
        //clear auth data from browser
        window.localStorage.removeItem('TEXSMART_REALTY_USERDATA');

        //clear auth data from app store
        this.authDataInStore = null;
    },
  },
  getters: {

    getSavedRoute: (state) => state.lastRoute,

    getAccessToken: (state) => {
      return state.authDataInStore ? state.authDataInStore.access_token : null;
    },

    getRefreshToken: (state) => state.authDataInStore && state.authDataInStore.refresh_token,

    getUserRoles: (state) => {
      let roles = []
      if(state.authDataInStore && state.authDataInStore.roles) {
        roles = state.authDataInStore.roles
      }
      return roles;
    },

    getAccessTokenExpirationTime: (state) => state.authDataInStore && state.authDataInStore.accessTokenExpirationTime,

    getRefreshTokenExpirationTime: (state) => state.authDataInStore && state.authDataInStore.refreshTokenExpirationTime,

    getUserInfo: (state) => state.authDataInStore,
  },
});