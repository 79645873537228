import axios from "axios";
import { useAuthStore } from '@/stores/store';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const router = useRouter();
        const routeTo = (line) => {
          router.push(line);
        };

        return {
            routeTo,
        }
    }, 
    data() {
        return {
          AUTHENTICATION_BASE_URL: process.env.VUE_APP_AUTHENTICATION_ROOT_URL,
          config: null,
        };
      },
    methods: {
        getOTPfromAuthServer: async function (email) {
            const url  = this.AUTHENTICATION_BASE_URL + '/request-otp';
            let response = await axios
            .post(url, {email : email})
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        getUserDetailsFromAuthServer: async function () {
          let authDataInStore = await useAuthStore().getUserInfo;
          if(authDataInStore != null || authDataInStore != undefined) {
            const url = this.AUTHENTICATION_BASE_URL + '/users/' + authDataInStore.user_uuid;
            let response = await axios
            .get(url,this.config)
            .catch((err) => {
              return err.response;
            });
            return response;
          } else {
            return null;
          }
        },
        signIn: async function (email, otp) {
            const url  = this.AUTHENTICATION_BASE_URL + '/sign-in';
            let response = await axios
            .post(url, {email : email, otp: otp})
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        getNewAuthToken: async function (refreshToken) {
          const url = process.env.VUE_APP_AUTHENTICATION_ROOT_URL + '/refresh'
          let response = await axios
            .post(url, { refresh_token: refreshToken })
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        calculateAccessTokenExpirationTime: function (tokenData) {
          const currentTimestamp = Date.now();
          // Default expiration time set for access token = 23 hours  = 23*60*60 = 82,800 sec
          // For testing purpose let's make expiration time to 1 min
          let accessTokenExpirationTime = currentTimestamp + ((tokenData.expires_in || 3600) * 1000);
          return {
            ...tokenData,
            "accessTokenExpirationTime": accessTokenExpirationTime
          }
        },

        calculateRefreshTokenExpirationTime: function (tokenData) {
          const currentTimestamp = Date.now();
          let refreshTokenExpirationTime = currentTimestamp + (579600 * 1000);
          return {
            ...tokenData,
            "refreshTokenExpirationTime": refreshTokenExpirationTime
          }
        },

        updateUserDetailsInApp: async function () {
          let response = await this.getUserDetailsFromAuthServer();
          if(response == null) {
            return false;
          }
          else if(response.status != undefined && response.status == 200) {
            await useAuthStore().updateUserDetails(response.data);
            return true;
          } else {
            return false;
          }
        },

        handleTokenValidationAndRenewal: async function (renewToken = false) {
            const currentTimestamp = Date.now();
            const tokenFromLocalStorage = JSON.parse(window.localStorage.getItem('TEXSMART_REALTY_USERDATA'));
            if(renewToken == true) {
              return await useAuthStore().renewToken(tokenFromLocalStorage.refresh_token);
            }
            else if (currentTimestamp < this.getAccessTokenExpirationTime) {
              return true;
            } 
            if(!tokenFromLocalStorage) {
              return false;
            } else if (currentTimestamp < tokenFromLocalStorage.accessTokenExpirationTime) {
              await useAuthStore().saveAuthData(tokenFromLocalStorage);
              return true;
            } else if (currentTimestamp < tokenFromLocalStorage.refreshTokenExpirationTime) {
              // Renew accesstoken from refresh token
              await useAuthStore().renewToken(tokenFromLocalStorage.refresh_token);
              return true;
            } else {
              return false;
            }
        },

        redirectToPage: async function (name) {
          this.routeTo({
            name: name
          });
        },

        getAccessToken: async function() {
          let accessToken = await useAuthStore().getAccessToken;
          if(!accessToken && window.localStorage.getItem('TEXSMART_REALTY_USERDATA')) {
            let authData = JSON.parse(window.localStorage.getItem('TEXSMART_REALTY_USERDATA'));
            accessToken = authData.access_token;
          }
          return accessToken;
        },

        refreshPage: function() {
          window.location.reload();
        },

        isLoggedIn: function() {
          if(useAuthStore().getAccessToken != null) {
            return true;
          } else if (window.localStorage.getItem('TEXSMART_REALTY_USERDATA') != null) {
            return true;
          } else {
            return false
          }
        }
    }
}