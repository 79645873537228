// axios-interceptors.js
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/store';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from '../App.vue';

// Create a Vue app instance and Pinia instance for use outside components
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);

const router = useRouter();

// Token refresh-related variables
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};


// Request interceptor
axios.interceptors.request.use(
  async (config) => {
    // Check if the request URL requires the Authorization header
    if (config.url.includes('/proxy-auth-service/sign-up') || config.url.includes('/proxy-auth-service/sign-in')) {
      return config;
    } else if (config.url.includes('/mortgage-customers-service') 
        ||config.url.includes('/proxy-auth-service/users')) {
        let accessToken = await useAuthStore().getAccessToken;
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    config.headers['X-Gateway-Validation-Id'] = process.env.VUE_APP_X_GATEWAY_VALIDATION_ID;
    config.headers['Client'] = process.env.VUE_APP_CLIENT_APP;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // Add any response handling logic if needed
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Add any error handling logic if needed
    if (error.response.status == 401 && !originalRequest._retry && !originalRequest.url.includes("/sign-in") && !originalRequest.url.includes("/refresh")) {
      // Handle 401 Unauthorized responses
      // Mark the request as already retried to avoid loops
      originalRequest._retry = true;

        // Check if a token refresh is already in progress
        if (!isRefreshing) {
          isRefreshing = true;

          try {
            let res = await useAuthStore().handleTokenValidation(true);
            if (!res) {
              router.push({ name: 'AuthLogin' });
            } else {
              // Process the queue after successfully refreshing the token
              const tokenFromLocalStorage = JSON.parse(window.localStorage.getItem('TEXSMART_REALTY_USERDATA'));
              if(failedQueue.length <= 0) {
                //buffer time to wait to for the new token to get updated in the database before making the retry calls
                await new Promise(resolve => setTimeout(resolve, 2000));
              }
              processQueue(null, tokenFromLocalStorage.access_token);

              // Set the isRefreshing flag to false
              isRefreshing = false;

              // Update the original request with the new access token and retry it
              originalRequest.headers['Authorization'] = `Bearer ${tokenFromLocalStorage.access_token}`;
              return axios(originalRequest);
            }
          }
          catch (err) {
            processQueue(err, null);
            return Promise.reject(err);
          }
          finally {
            isRefreshing = false;
          }
        }

        // If token refresh is already in progress, queue the requests
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then((token) => {
          // Once the token is refreshed, retry the original request
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });

    } else {
      return Promise.reject(error);
    }
  }
);