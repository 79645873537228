<template>
    <div>
        <Navbar />
        <!-- Start Hero -->
        <section class="swiper-slider-hero position-relative d-block vh-100" id="home">
            <div class="swiper-container">
                <swiper class="swiper-wrapper" :modules="modules" :autoplay="true" :scrollbar="{ draggable: true }">
                    <swiper-slide v-for="item in StartupData" :key="item"
                        class="swiper-slide flex items-center overflow-hidden">
                        <div class="slide-inner slide-bg-image d-flex align-items-center "
                            style="background-size:cover;background-repeat: no-repeat;" :style='{ backgroundImage: "linear-gradient(rgba(138, 218, 250, 0.2), rgba(1, 160, 246, 0)), url(" + item.image + ")",}'>
                            <div class="bg-overlay">
                            </div>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="title-heading text-center">
                                            <!-- <h1 class="display-5 text-white title-dark fw-bold mb-4">{{ item.title1 }}<br>{{
                                                item.title2 }}</h1> -->
                                            <!-- <p class="para-desc mx-auto text-white-50">{{ item.desc }}</p> -->
                                        </div>
                                    </div><!--end col-->
                                </div><!--end row-->
                            </div><!--end container-->
                        </div><!-- end slide-inner -->
                    </swiper-slide> <!-- end swiper-slide -->
                </swiper>
                <!-- end swiper-wrapper -->

                <!-- swipper controls -->
                <!-- <div class="swiper-pagination"></div> -->
                <!-- <div class="swiper-button-next border rounded-circle text-center"></div>
                <div class="swiper-button-prev border rounded-circle text-center"></div> -->
                <div id="index-title">
                    <div class="row justify-content-center">
    <h2 class="display-8 text-white text-center title-dark fw-bold mb-4" style="text-shadow: 1px 1px 0px black;
font-family:Georgia, 'Times New Roman', Times, serif">Your Expert Realtor for Seamless<br>Home Transactions</h2>
  </div>
                    
                </div>
                <!-- <div id="index-head">
                    <a href="" class="btn btn-primary" style="box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;">
                        <h6>Get Started!</h6>
                        <router-link to="/get-started"><h6 style="color: white;">Get Started!</h6></router-link>
                    </a>
                </div> -->
            </div><!--end container-->
        </section><!--end section-->
        <!-- Hero End -->
        <Feature />
        <!-- <Cta /> -->
        <Project />
        <!-- <Pricing /> -->
        <!-- <Team /> -->
        <Cta />
        <!-- <GetInTouch /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar-Startup.vue';
import Feature from '@/components/Feature/Feature-Startup';
import Cta from '@/components/Cta/Cta-Startup.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Scrollbar, Autoplay } from 'swiper/modules';
import 'swiper/css';
import Project from '@/components/Project.vue';
//import Pricing from '@/components/Pricing.vue';
//import Team from '@/components/Team/Team-Startup.vue';
//import Blog from '@/components/Blog.vue';
/* import GetInTouch from '@/components/GetInTouch.vue'; */
import Footer from '@/components/Footer/Footer-Startup.vue'


export default {
    name: "IndexVue",
    data() {
        return {
            StartupData: [
                {
                    image: require("../assets/images/house10.jpg"),
                    title1: "Business Planning, Strategies",
                    title2: "and Execution",
                    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
                    button: "Contact us"
                },
                {
                    image: require("../assets/images/house16.jpg"),
                    title1: "Financial Projections And Analysis",
                    title2: "Marketing",
                    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
                    button: "Get Started"
                },
                {
                    image: require("../assets/images/house14.jpg"),
                    title1: "Business Planning, Strategies",
                    title2: "and Execution",
                    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
                    button: "Get Started"
                },
                {
                    image: require("../assets/images/house15.jpg"),
                    title1: "Business Planning, Strategies",
                    title2: "and Execution",
                    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
                    button: "Get Started"
                }
            ]
        }
    },
    components: {
        Navbar,
        Feature,
        Swiper,
        SwiperSlide,
        Cta,
        Project,
        //Pricing,
        //Team,
        //Blog,
        /* GetInTouch, */
        Footer
    },
    setup() {
        return {
            modules: [Navigation, Scrollbar, Autoplay],
        };
    }
}
</script>

<style  scoped>
.swiper-slider-hero {
    margin-top: 70px;
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 50%;
    object-fit: cover;
}

#index-head {
    position: absolute;
    margin-top:-15%;
    margin-left:43%;
    z-index:10005;
}

#index-title {
    position: absolute;
    margin-top: -65vh;
    text-align: center;
    margin-left:31%;
    z-index:10004;
}

@media screen and (max-width: 984px) {
    #index-head {
        margin-top:-135px;
        margin-left:42vw;
    }
    #index-title {
        margin-top: -65vh;
        margin-left: 8vh;
    }
}

@media screen and (max-width: 429px) {
    #index-head {
        margin-top:-42%;
        margin-left:32%;
    }
    #index-title {
        margin-left: 3vh;
    }
}

@media screen and (max-width: 392px) {
    #index-title {
        margin-top: -600px;
    }
}

@media screen and (max-width: 392px) {
    #index-title {
        margin-top: -500px;
    }
}

</style>